// Fetching Input Mask
loadScriptOnDemand('/_ui/responsive/common/js/vendor/jquery.inputmask.min.js', function() {
	// Mobile Phone Input Mask
	$('.mobile').inputmask({
		mask: '(999) 999-9999',
		placeholder: ' ',
		showMaskOnHover: false,
		showMaskOnFocus: false,
		positionCaretOnClick: 'none',
		removeMaskOnSubmit: true,
		jitMasking: true
	});
});

$('#phoneNumberField').focus();
$('.dealsMobileField, .dealsEmailField').removeClass('mandatoryError');
$('.enterMobileNumberError, .enterValidMobileNumberError, .emailError, .validEmailError').hide();

function medalliaDecline() {
	if (getCookieByName('signUpAccepted') != 'Yes') {
		setCookie("signUpDeclined", "Yes", 15, "", true);
	}
}

function resetTooltip() {
	$('#signUpForDealsEmailError').hide();
	$('.medalliaForm .emailChk').parent().removeClass('mandatoryError');
}

$("#emailField").keypress(function(event) {
	if (event.keyCode === 13) {
		$("#medalliaSubmit").click();
		return false;
	}
});
// On checked event hide error
$(document).on('click', '#signupAgreementCheck', function() {
	let suCheck = $('#signupAgreementCheck').prop("checked");
	if (suCheck) {
		$('.signUpTermsCheckbox').removeClass('mandatoryError');
		$('.signUpAgreementError').hide();
	}
});
function medalliaSubmission() {
	validateSignUpMobileNumber();
	validateSignUpEmail();
	let emailText = !$(".dealsEmailField").hasClass("mandatoryError") ? $("#emailField").val() : "";
	let phoneNumberText = !$(".dealsMobileField").hasClass("mandatoryError") ? $("#phoneNumberField").val() : "";
	let signUpAgreement = $('#signupAgreementCheck').prop("checked");
	$('.singup-success-msg, .oneValidInputError').addClass('d-none');

	if ($('.medalliaForm').find('.validValue').length > 0) {
		if ($('.medalliaForm').find('.mandatoryError').length > 0) {
			$('.medalliaForm').find('.mandatoryError').find('input.form-control').focus();
			return false;
		}
		$('#medalliaSubmit').attr('disabled', true);
		$('.validValuesError, signUpAgreementError').hide();
		$('.signUpTermsCheckbox').removeClass('mandatoryError');

		if (!phoneNumberText == '' && !signUpAgreement) {
			$('.signUpTermsCheckbox').addClass('mandatoryError');
			$('.signUpAgreementError').css("display","flex");
			$('#medalliaSubmit').attr('disabled', false);
		} else {
			if (!emailText == '') {
				$('.signUpAgreementError').hide();
			}
			if (!phoneNumberText == '') {
				$('.signUpTermsCheckbox').removeClass('mandatoryError');
				$('.signUpTermsCheckbox, .signUpAgreementError').hide();
			}
			var signUpForSpecialsData = {
				"emailAddress": emailText,
				"primaryPhoneNumber": phoneNumberText
			};
			$.ajax({
				url: "/rac-specials/sign-up-for-dealsViaEmail",
				type: "post",
				data: JSON.stringify(signUpForSpecialsData),
				contentType: 'application/json',
				success: function(data) {
					$('.dealsMobileField, .dealsEmailField').removeClass('mandatoryError');
					$('.dealsMobileField #phoneNumberField, .dealsEmailField #emailField').val('');
					$('.enterMobileNumberError, .enterValidMobileNumberError, .emailError, .validEmailError').hide();
					if (data != null && (data.statusCode == "200" || data.statusCode == "200 OK")) {
						$('.successchk').addClass('d-none');
						if ($('.medalliaForm').find('.validValue').length === 2) {
							$('#phoneNumberField, #emailField').attr('disabled', true);
							$('#phoneNumberField, #emailField').parents('.input-group-icon').addClass('disabled');
							$("#phoneNumberField, #emailField").val('');
							$('.singup-success-msg').removeClass('d-none');
							$('.oneValidInputError').addClass('d-none');
						} else {
							if ($('.dealsMobileField').hasClass('validValue')) {
								$('.dealsMobileField').removeClass('validValue');
								$('#phoneNumberField').attr('disabled', true);
								$('#phoneNumberField').val('');
								$('.dealsMobileField').find('.input-group-icon').addClass('disabled');
								$('.singup-success-msg').removeClass('d-none');
								$('.oneValidInputError').addClass('d-none');
							}
							if ($('.dealsEmailField').hasClass('validValue')) {
								$('.dealsEmailField').removeClass('validValue');
								$('#emailField').attr('disabled', true);
								$("#emailField").val('');
								$('.dealsEmailField').find('.input-group-icon').addClass('disabled');
								$('.singup-success-msg').removeClass('d-none');
								$('.oneValidInputError').addClass('d-none');
							}
						}
						if ($('#medalliaModal .input-group-icon.disabled').length == $('#medalliaModal .input-group-icon').length) {
							$('#medalliaSubmit').attr('disabled', true);
						} else {
							$('#medalliaSubmit').attr('disabled', false);
						}
						$(".checkcircle").show();
						setCookie("signUpAccepted", "Yes", 100, "", true);
						return false;
					} else if (data != null && (data.statusCode != "200" || data.statusCode != "200 OK")) {
						$('.oneValidInputError').removeClass('d-none');
					}
				},
				error: function(error) {
				}
			});
		}

	} else {
		$('.oneValidInputError').removeClass('d-none');
	}
	return false;
}

function validateSignUpMobileNumber() {
	let mobile = $('.dealsMobileField .mobile').val();
	if (mobile.length > 0) {
		var phoneRegex = /^(?:\([2-9]\d{2}\)\ ?|[1-9]\d{2}(?:\-?|\ ?))[0-9]\d{2}[- ]?\d{4}$/;
		if (mobile === "") {
			$('.dealsMobileField').removeClass('validValue');
			$('.dealsMobileField').addClass('mandatoryError');
			$('.enterMobileNumberError').show();
			$('.enterValidMobileNumberError').hide();
			$('.dealsMobileField').find('.successchk').addClass('d-none');
		} else if (!(phoneRegex.test(mobile))) {
			$('.dealsMobileField').removeClass('validValue');
			$('.dealsMobileField').addClass('mandatoryError');
			$('.enterValidMobileNumberError').css("display","flex");
			$('.enterMobileNumberError').hide();
			$('.dealsMobileField').find('.successchk').addClass('d-none');
		} else {
			$('.dealsMobileField #phoneNumberField').attr('title', '');
			$('.dealsMobileField').addClass('validValue');
			$('.enterMobileNumberError').hide();
			$('.enterValidMobileNumberError').hide();
			$('.dealsMobileField').removeClass('mandatoryError');
			$('.dealsMobileField').find('.successchk').removeClass('d-none');
		}
	} else {
		$('.dealsMobileField').removeClass('validValue');
		$('.enterMobileNumberError').hide();
		$('.enterValidMobileNumberError').hide();
		$('.dealsMobileField').removeClass('mandatoryError');
		$('.dealsMobileField').find('.successchk').addClass('d-none');
		$('.signUpTermsCheckbox').removeClass('mandatoryError');
		$('.signUpAgreementError').hide();
	}
}

function validateSignUpEmail() {
	let email = $('.dealsEmailField .emailChk').val();
	if (email.length > 0) {
		var emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (email === "") {
			$('.dealsEmailField').removeClass('validValue');
			$('.dealsEmailField').addClass('mandatoryError');
			$('.emailError').show();
			$('.validEmailError').hide();
			$('.dealsEmailField').find('.successchk').addClass('d-none');
		} else if (!(emailRegex.test(email))) {
			$('.dealsEmailField').removeClass('validValue');
			$('.dealsEmailField').addClass('mandatoryError');
			$('.validEmailError').css("display","flex");
			$('.emailError').hide();
			$('.dealsEmailField').find('.successchk').addClass('d-none');
		} else {
			$('.dealsEmailField').addClass('validValue');
			$('.emailError').hide();
			$('.validEmailError').hide();
			$('.dealsEmailField').removeClass('mandatoryError');
			$('.dealsEmailField').find('.successchk').removeClass('d-none');
		}
	} else {
		$('.dealsEmailField').removeClass('validValue');
		$('.dealsEmailField').removeClass('mandatoryError');
		$('.emailError').hide();
		$('.validEmailError').hide();
		$('.dealsEmailField').find('.successchk').addClass('d-none');
	}
}
//store modal
$(document).on('click', '#enterZipCode_Nav', function() {
	$('#enterZipCode_Nav').focus();
});